import React from "react"
import { Link } from "gatsby"
import "../styles/layout.css"

const Layout = ({ children }) => {
  
  return (
    <>
      <header background="black" py=".2rem" px="5vw">
        <Link to="/" className="home">
           <h1 color="white" fontSize="md">ILOVEMY<span color="#8DD2FF">DOG</span>SOMUCH</h1>
        </Link>
       
          {

        }
      </header>
      <main w="90%|90%|75%|60%|60%">{children}</main>
      
      {//<div dangerouslySetInnerHTML={{ __html: `<button id="gdpr_link"  onclick="window._sp_.loadPrivacyManagerModal(250995)">Privacy Settings</button>` }}/>
      //<div dangerouslySetInnerHTML={{ __html: `<button id="ccpa_link"  onclick="window._sp_ccpa.loadPrivacyManagerModal(null, '5f612107acade00adf4ce4d3')">Do Not Sell My Info</button>`}}/>
      /*
      <script dangerouslySetInnerHTML={{ __html: `
          window.__tcfapi('getTCData',2,function(data,success){
              console.log("data.gdprApplies: "  + data.gdprApplies);
              if(data.gdprApplies){
                  document.getElementById("gdpr_link").style.display = "block";
              }
          });
          window.__uspapi('getCustomVendorRejects',1,function(uspData,success){
              console.log("uspData: "+uspData);
              if(uspData.ccpaApplies){
                  document.getElementById("ccpa_link").style.display = "block";
              }
          });
        ` }}
        /> */ }
    </>
  )
}

export default Layout