import React from "react"
import { Helmet} from "react-helmet"
import { StaticQuery, graphql } from "gatsby"


export const Seo = ({description, keywords, title, image, url, author}) => {
  return (
    <StaticQuery
        query = {detailsQuery}
        render = {data => {
          const metaDescription = description || data.site.siteMetadata.description
          const metaTitle = title || data.site.siteMetadata.title
          const metaAuthor = author || data.site.siteMetadata.author
          const metaUrl = url || data.site.siteMetadata.url
          const metaImage = image || data.site.siteMetadata.image
          const metaKeywords = keywords || ["blog", "dog", "dogs"]
          const metaFBAppID = "591601367599084"
          return (
            <Helmet 
              title={title}
              meta={[
                {
                  name: `description`,
                  content: metaTitle,
                },
                {
                  property: `og:locale`,
                  content: `en_US`,  
                },
                {
                  property: `og:type`,
                  content: `article`,
                },
                {
                  property: `og:title`,
                  content: metaTitle,
                },
                {
                  property: `og:description`,
                  content: metaTitle,
                },
                {
                  property: `og:url`,
                  content: metaUrl,
                },
                {
                  property: `og:site_name`,
                  content: `I Love My Dog So Much`,
                },
                {
                  property: `fb:app_id`,
                  content: metaFBAppID,
                },
                {
                  property: `og:image`,
                  content: metaImage,
                },
                {
                  property: `og:image:secure_url`,
                  content: metaImage,
                },
                {
                  property: `og:image:width`,
                  content: `1200`,
                },
                {
                  property: `og:image:height`,
                  content: `630`,
                },
                {
                  name: `twitter:card`,
                  content: `summary_large_image`,
                },
                {
                  name: `twitter:creator`,
                  content: metaAuthor,
                },
                {
                  name: `twitter:title`,
                  content: metaTitle,
                },
                {
                  name: `twitter:description`,
                  content: metaTitle,
                },
                {
                  name: `twitter:image`,
                  content: metaImage,
                },
                {
                  name: `Author`,
                  content: `I Love My Dog`,
                },
                {
                  property: `article:author`,
                  content: `https://www.facebook.com/ilovemydogfans`,
                },
                {
                  property: `article:publisher`,
                  content: `https://www.facebook.com/ilovemydogfans`,
                }
              ].concat(metaKeywords && metaKeywords.length > 0 ? {
                    name: `keywords`,
                    content: metaKeywords.join(`,`),
                  } : []
                )}
              />
          )
        }}
    />
  )  
}

const detailsQuery = graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          image
          url
        }
      }
    }
`

export default Seo